<template>
	<div class="box">
		<div
			:id="video.slug"
			class="lesson-card-item"
			@click.stop="modalOpen('video_', { id: video.id, src: video.file, poster: video.image_video[0].image, progress: true })"
		>
			<div
				class="lesson-card-pic icon-hover"
				:style="video.image_video[0].image | bgrDynamicImageCover"
			>
				<div class="icon icon-video-play" />
			</div>
			<h2 class="lesson-card-title">
				{{ video.name }}
			</h2>
		</div>
	</div>
</template>

<script>
export default {
    name: 'BaseCardVideo',
    props: {
        video: {
            type: Object,
            default: null
        },
    },
    methods: {
        modalOpen(name, payload = null) {
            this.$store.commit('modal/open', {name, payload})
        },
    }
}
</script>
