<template>
	<div class="pagination">
		<base-button
			v-if="!isAnimation"
			class="pagination__button"
			light-border
			transparent
			@clickButton="showMore"
		>
			{{ nameButtonMore }}
			<div
				v-if="isMore"
				class="icons"
			>
				<div class="icons__icon" />
				<div class="icons__icon" />
				<div class="icons__icon" />
			</div>
		</base-button>
		<base-button
			v-else
			class="more-button"
			blue
			:style="'display: inline-block; padding-top: 0;'"
			@clickButton="showMore"
		>
			{{ nameButtonMore }}
		</base-button>
	</div>
</template>

<script>
import BaseButton from './BaseComponents/BaseButton';

export default {
    name: 'MorePagination',
    components: {
        BaseButton
    },

    props: {
        isMore: {
            type: Boolean,
            default: false
        },
        nameButtonMore: {
            type: String,
            default: null
        },
        isAnimation: {
            type: Boolean,
            default: false
        }
    },

    methods: {
        showMore() {
            this.$emit('clickButton');
        },
    },
}
</script>

<style lang="less" scoped>
.pagination {
    text-align: center;

    &__button {
        position: relative;
        display: inline-block;
        line-height: 58px;
        padding: 0 22px;
        border-radius: 5px;
        letter-spacing: 0.02em;
        font-size: 16px;
        text-decoration: none !important;
        cursor: pointer;
        .transition;

        &:focus {
            background: none;
            color: #000;
        }
        &:hover {
            background: #EF722E;
            border-color: #EF722E;
            box-shadow: 10px 10px 25px rgba(239, 114, 46, 0.2);
            color: #fff;
        }
    }
}
.icons {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 5px;
    background: #fff;
    text-align: center;
    font-size: 0;

    &__icon {
        opacity: 0.5;
        display: inline-block;
        vertical-align: middle;
        width: 10px;
        height: 10px;
        margin: 0 5px;
        background: #999;
        -webkit-border-radius: 200px;
        -moz-border-radius: 200px;
        border-radius: 200px;
        animation: button-sending-icon 0.8s infinite;

        &:nth-child(1) {
            animation-delay: 0.1s;
        }
        &:nth-child(2) {
            animation-delay: 0.2s;
        }
        &:nth-child(3) {
            animation-delay: 0.3s;
        }
    }
}
.more-button {
    height: 44px;
    line-height: 44px;
    margin-top: 10px;
    letter-spacing: 0.02em;
    font-weight: 400;
    font-size: 16px;
}
</style>
