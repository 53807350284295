<template>
	<div @mouseup="ctrlDropdown">
		<div class="contest-cover">
			<div class="contest-cover-video">
				<video
					ref="bannerInternshipVideo"
					src="https://ea5ec095-4ab2-4f77-811e-49e733f2acc8.selcdn.net/static_video/internshipBannerVideo.mp4"
					autoplay
					muted
					loop
				/>
			</div>
			<div class="contest-cover-content">
				<div class="content">
					<Breadcrumb :page-name="'Стажировка в активах АФК «Система»'" />
					<div class="grid">
						<div class="box">
							<h3 class="page-title">
								Стажировка <br>в активах АФК &laquo;Система&raquo;
							</h3>
							<div class="text">
								<p>Только вверх на Лифте в будущее</p>
							</div>
							<br>
							<br>
							<div class="main-action-block">
								<div class="main-action-content">
									<a
										href="#"
										class="main-play-item"
										@click.stop.prevent="modalOpen('mBanVideo', {
											src: 'https://ea5ec095-4ab2-4f77-811e-49e733f2acc8.selcdn.net/static_video/internshipModalVideo.mp4'
										})"
									>
										<img
											:src="'/img/icons/icon-play-lg-white.svg' | prodSrcImage"
											alt=""
										>
										<div class="main-play-title">
											<span>Смотреть <br>ролик</span>
										</div>
									</a>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>

		<section
			class="section-simple wow"
		>
			<div class="content">
				<div class="main-button-group">
					<div class="grid">
						<div class="box">
							<base-button
								v-scroll-to="'#anchor-about'"
								class="main-button"
								:style="'align-items: left; justify-content: left;'"
								orange
							>
								<span
									class="main-button-text"
								>Узнай о профессиях</span>
								<span class="main-button-icon">
									<img
										:src="'/img/icons/icon-youtube-white.svg' | prodSrcImage"
										alt="Узнай о профессиях"
									>
								</span>
							</base-button>
						</div>
						<div class="box">
							<base-button
								v-scroll-to="'#anchor-job'"
								class="main-button"
								:style="'align-items: left; justify-content: left;'"
								blue
							>
								<span class="main-button-text">Каталог стажировок</span>
								<span class="main-button-icon">
									<img
										:src="'/img/icons/icon-landing-job.svg' | prodSrcImage"
										alt="Каталог стажировок"
									>
								</span>
							</base-button>
						</div>
						<div class="box">
							<base-button
								v-scroll-to="'#anchor-faq'"
								class="main-button"
								:style="'align-items: left; justify-content: left;'"
								gray
							>
								<span class="main-button-text">Вопросы и ответы</span>
								<span class="main-button-icon">
									<img
										:src="'/img/icons/icon-program-list-white.svg' | prodSrcImage"
										alt="Вопросы и ответы"
									>
								</span>
							</base-button>
						</div>
					</div>
				</div>
			</div>
		</section>

		<section
			id="anchor-about"
			class="section-simple light wow fadeIn"
		>
			<div class="content">
				<h3 class="block-title">
					Выбери то, что тебя драйвит, и начни карьеру!
				</h3>
				<div class="text md dark">
					<p>
						На “Лифте в будущее” мы собрали все предложения по стажировкам в активах АФК “Система”. Выберите
						направление, в котором вам интересно развиваться, и уже этим летом погрузитесь с головой в
						увлекательные задачи.
					</p>
				</div>
				<br>
				<br>
				<div class="lesson-card-group">
					<div class="grid">
						<base-card-video
							v-for="video in sortedVideos"
							:key="video.id"
							:video="video"
						/>
					</div>
					<div class="center">
						<more-pagination
							v-if="(currentPage * maxPerPage) < (videos && videos.length)"
							:name-button-more="'Показать еще'"
							@clickButton="loadMore"
						/>
					</div>
				</div>
			</div>
		</section>

		<section
			class="section-simple wow fadeIn"
		>
			<div class="content">
				<h3 class="block-title">
					Какие возможности открывает программа стажировок
				</h3>
				<div class="jury-group">
					<div class="grid">
						<div
							v-for="item in capabilities"
							:key="item.id"
							class="box"
						>
							<div class="jury middle">
								<img
									:src="item.image | prodSrcImage"
									alt="Программа стажировок"
									class="jury-avatar"
								>
								<div class="jury-content">
									<div class="jury-job">
										{{ item.title }}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>

		<section
			id="anchor-job"
			class="section-simple light wow fadeIn"
		>
			<div class="content">
				<h3 class="block-title">
					Каталог стажировок
				</h3>
				<div class="internship-bar-block">
					<div class="field-wrap">
						<div
							class="select search"
							:class="{ 'active': isOpenCity }"
						>
							<select>
								<option value="null">
									Все города
								</option>
								<option
									v-for="(city, index) in cities"
									:key="index"
									:value="city.name"
								>
									{{ city.name }}
								</option>
							</select>
							<div
								class="select-button"
								@click="openDropdownCity"
							>
								<label class="field-label">
									<input
										ref="inptCity"
										v-model.trim="currentCity.text"
										type="text"
										readonly
										class="select-value field"
										placeholder="Город"
									>
									<div
										class="label"
										@click="openDropdownCity"
									>Город
									</div>
								</label>
								<div
									class="select-arrow"
									:style="'/img/icons/icon-arrow-select.svg' | bgrImageCover"
								/>
							</div>
							<div
								v-if="isOpenCity && cities && cities.length"
								ref="dropdownCity"
								tabindex="1"
								class="select-dropdown"
							>
								<div
									class="select-option"
									:class="{ 'active': isSelectedCity(null), 'pseudo-active': isPseudoSelected(null) }"
									@click.stop="selectOptionCity(null)"
								>
									Все города
								</div>
								<div
									v-for="(city, index) in cities"
									:key="index"
									class="select-option"
									:class="{ 'active': isSelectedCity(index), 'pseudo-active': isPseudoSelected(index) }"
									@click.stop="selectOptionCity(index)"
								>
									{{ city.name }}
								</div>
							</div>
						</div>
					</div>
					<div class="field-wrap">
						<div
							class="select search"
							:class="{ 'active': isOpenD }"
						>
							<select>
								<option value="null">
									Все направления
								</option>
								<option
									v-for="(direction, index) in directions"
									:key="index"
									:value="direction.id"
								>
									{{ direction.name }}
								</option>
							</select>
							<div
								class="select-button"
								@click="openDropdownD"
							>
								<label class="field-label">
									<input
										ref="inpt"
										v-model.trim="currentDirection.text"
										type="text"
										readonly
										class="select-value field"
										placeholder="Направление"
									>
									<div
										class="label"
										@click="openDropdownD"
									>Направление
									</div>
								</label>
								<div
									class="select-arrow"
									:style="'/img/icons/icon-arrow-select.svg' | bgrImageCover"
								/>
							</div>
							<div
								v-if="isOpenD && directions && directions.length"
								ref="dropdownDirection"
								class="select-dropdown"
							>
								<div
									class="select-option"
									:class="{ 'active': isSelectedD(null), 'pseudo-active': isPseudoSelected(null) }"
									@click.stop="selectOptionD(null)"
								>
									Все направления
								</div>
								<div
									v-for="(direction, index) in directions"
									:key="index"
									class="select-option"
									:class="{ 'active': isSelectedD(index), 'pseudo-active': isPseudoSelected(index) }"
									@click.stop="selectOptionD(index)"
								>
									{{ direction.name }}
								</div>
							</div>
						</div>
					</div>
					<div class="field-wrap">
						<div
							class="select search"
							:class="{ 'active': isOpenCompany }"
						>
							<select>
								<option value="null">
									Все компании
								</option>
								<option
									v-for="(company, index) in companies"
									:key="index"
									:value="company.name"
								>
									{{ company.name }}
								</option>
							</select>
							<div
								class="select-button"
								@click="openDropdownCompany"
							>
								<label class="field-label">
									<input
										ref="inpt"
										v-model.trim="currentCompany.text"
										type="text"
										readonly
										class="select-value field"
										placeholder="Компания"
									>
									<div
										class="label"
										@click="openDropdownCompany"
									>Компания
									</div>
								</label>
								<div
									class="select-arrow"
									:style="'/img/icons/icon-arrow-select.svg' | bgrImageCover"
								/>
							</div>
							<div
								v-if="isOpenCompany && companies && companies.length"
								ref="dropdownCompany"
								class="select-dropdown"
							>
								<div
									class="select-option"
									:class="{ 'active': isSelectedCompany(null), 'pseudo-active': isPseudoSelected(null) }"
									@click.stop="selectOptionCompany(null)"
								>
									Все компании
								</div>
								<div
									v-for="(company, index) in companies"
									:key="index"
									class="select-option"
									:class="{ 'active': isSelectedCompany(index), 'pseudo-active': isPseudoSelected(index) }"
									@click.stop="selectOptionCompany(index)"
								>
									{{ company.name }}
								</div>
							</div>
						</div>
					</div>
					<div class="field-wrap">
						<span
							class="vacancies-filter-clear"
							@click="clearFilter"
						>Очистить фильтры</span>
					</div>
				</div>
				<div class="internship-card-group">
					<div class="grid">
						<div
							v-if="internships.length === 0"
							class="box"
						>
							<div class="internship-card-subtitle">
								Подходящих стажировок не найдено
							</div>
						</div>
						<div
							v-for="item in internships"
							v-else
							:key="item.id"
							class="box"
						>
							<a
								:href="item.url"
								class="internship-card-item"
							>
								<div
									class="internship-card-pic"
									:style="item.logo | bgrDynamicImageCover"
								/>
								<div class="internship-card-content">
									<h3 class="internship-card-title">{{ item.name }}</h3>
									<div class="internship-card-subtitle">{{ item.description }}</div>
									<div class="more-link">
										Подробнее
										<img
											:src="'/img/icons/icon-angle-right-color.svg' | prodSrcImage"
											alt=""
										>
									</div>
								</div>
							</a>
						</div>
					</div>
				</div>
			</div>
		</section>

		<section
			id="anchor-faq"
			class="section-simple wow fadeIn"
		>
			<div class="content">
				<h3 class="block-title">
					Вопросы и ответы
				</h3>
				<div class="faq-group">
					<div
						v-for="(item, index) in infoQA"
						:key="index"
						:class="{'active': isActive === index}"
						class="faq-item"
					>
						<div
							class="faq-header"
							@click="toggleItem(index)"
						>
							<h4 class="faq-title">
								{{ item.question }}
							</h4>
						</div>
						<div
							:class="{'item-active': isActive == index }"
							class="faq-body"
						>
							<div class="text dark md">
								<p v-if="item.getMail && item.answer2">
									<span>{{ item.answer }}</span>
									<span v-html="item.getMail" />
									<span>{{ item.answer2 }}</span>
								</p>
								<p v-else>
									{{ item.answer }}
								</p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>

		<section
			class="section-simple light wow fadeIn"
		>
			<div class="content">
				<div class="contest-form-block">
					<h4 class="block-title">
						Вузам
					</h4>
					<div class="text dark sm">
						<p>
							Если вы представитель вуза и хотите присоединиться к программе стажировок, оставьте заявку с
							помощью формы ниже:
						</p>
					</div>
					<br>
					<form
						class="form"
						@submit.prevent="sendIntern"
					>
						<div class="grid">
							<div class="box">
								<div class="field-wrap">
									<label class="field-label">
										<input
											v-model.trim="$v.intern.full_name.$model"
											type="text"
											class="field"
											placeholder="ФИО"
										>
										<div class="label">ФИО</div>
										<span
											v-if="$v.intern.full_name.$error"
											class="help-block form-error"
										>
											{{
												!$v.intern.full_name.required ? 'Это обязательное поле' : $v.intern.full_name.alphaSpace ? 'Только буквы, пробел и дефис' : 'Введите корректное ФИО'
											}}
										</span>
									</label>
								</div>
							</div>
							<div class="box">
								<div class="field-wrap">
									<label class="field-label">
										<input
											v-model="$v.intern.email.$model"
											type="text"
											class="field"
											placeholder="Email"
										>
										<div class="label">Email</div>
										<span
											v-if="$v.intern.email.$error"
											class="help-block form-error"
										>
											{{
												!$v.intern.email.required ? 'Это обязательное поле' :
												!$v.intern.email.email ? 'Введите корректный e-mail' : 'Ошибка поля'
											}}
										</span>
									</label>
								</div>
							</div>
							<div class="box">
								<div class="field-wrap">
									<label class="field-label">
										<input
											v-model="$v.intern.phone.$model"
											v-mask="'+7(###)###-##-##'"
											type="text"
											class="field"
											placeholder="Телефон"
										>
										<div class="label">Телефон</div>
										<span
											v-if="$v.intern.phone.$error"
											class="help-block form-error"
										>
											{{
												!$v.intern.phone.required ? 'Это обязательное поле' : !$v.intern.phone.Phone ? 'Введите корректный номер телефона' : 'Ошибка поля'
											}}
										</span>
									</label>
								</div>
							</div>
							<div class="box">
								<div class="field-wrap">
									<label class="field-label">
										<input
											v-model="intern.city"
											type="text"
											class="field"
											placeholder="Город"
										>
										<div class="label">Город</div>
									</label>
									<span
										v-if="$v.intern.city.$error"
										class="help-block form-error"
									>
										{{
											!$v.intern.city.required ? 'Это обязательное поле' :
											!$v.intern.city.alphaSpace ? 'Введите корректное название города' : 'Ошибка поля'
										}}
									</span>
								</div>
							</div>
							<div class="box">
								<div class="field-wrap">
									<label class="field-label">
										<c-select
											v-model="$v.intern.university.$model"
											:name="'Вуз'"
											autosearch-education
											:is-clear="isUniversityClear"
											:default-data="intern.university"
											@selected="selectUniversity"
											@cleared="$v.intern.university.$reset"
										/>
									</label>
									<span
										v-if="$v.intern.university.$error"
										class="help-block form-error"
									>
										{{
											!$v.intern.university.required ? 'Это обязательное поле' :
											!$v.intern.university.alphaEduFac ? 'Введите корректное название учебного заведения' : 'Ошибка поля'
										}}
									</span>
								</div>
							</div>
							<div class="box">
								<div class="field-wrap">
									<label class="field-label">
										<input
											v-model.trim="$v.intern.students.$model"
											type="text"
											class="field"
											:isError="$v.intern.students.$error"
											placeholder="Число студентов"
										>
										<div class="label">Число студентов</div>
										<span
											v-if="$v.intern.students.$error"
											class="help-block form-error"
										>
											{{
												!$v.intern.students.required ? 'Это обязательное поле' : !$v.intern.students.onlyNumber ? 'Введите только цифры' : 'Ошибка поля'
											}}
										</span>
									</label>
								</div>
							</div>
						</div>
						<div class="field-wrap sm">
							<label class="checkbox-filter">
								<input
									v-model="$v.isPersonal.$model"
									type="checkbox"
									@change="$v.isPersonal.$touch()"
								>
								<span class="info">Соглашаюсь с условиями обработки <a
									href="/privacy-policy"
									target="_blank"
								>персональных данных</a></span>
								<div
									v-if="$v.isPersonal.$error"
									class="help-block form-error"
								>Необходимо подтверждение
								</div>
							</label>
						</div>
						<div class="field-wrap">
							<label class="checkbox-filter">
								<input
									v-model="$v.isAgree.$model"
									type="checkbox"
									@change="$v.isAgree.$touch()"
								>
								<span class="info">Соглашаюсь с условиями <a
									href="/agreement"
									target="_blank"
								>пользовательского соглашения</a></span>
								<div
									v-if="$v.isAgree.$error"
									class="help-block form-error"
								>Необходимо подтверждение
								</div>
							</label>
						</div>
						<base-button
							v-if="!isSended"
							class="send-button"
							orange
						>
							Отправить
						</base-button>
						<base-button
							v-else
							class="sign-in-button button button-preloader"
							orange
						>
							<div class="button-preloader-icons">
								<div class="button-preloader-icon" />
								<div class="button-preloader-icon" />
								<div class="button-preloader-icon" />
							</div>
						</base-button>
					</form>
				</div>
			</div>
		</section>

		<section
			class="section-simple wow fadeIn"
		>
			<div class="content">
				<h3 class="block-title">
					Контакты
				</h3>
				<div class="text dark sm">
					<p>
						Разъяснения и консультации по вопросам участия в стажерской программе можно получить у
						организаторов программы.
					</p>
				</div>
				<br>
				<div class="contacts-simple">
					<div class="contact-simple">
						<div class="info">
							Контактый телефон:
						</div>
						<a href="tel:+7(495)7374419">+7 (495) 737-44-19</a>
					</div>
					<div class="contact-simple">
						<div class="info">
							Электронная почта:
						</div>
						<a href="mailto:info@mail.lift-bf.ru">info@mail.lift-bf.ru</a>
					</div>
					<div class="contact-simple">
						<div class="info">
							Время работы:
						</div>
						с 11.00 до 18.00 по московскому времени
					</div>
				</div>
			</div>
		</section>
	</div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex';
import {required, sameAs, email} from 'vuelidate/lib/validators';
import {alphaSpace, Phone, onlyNumber, alphaEduFac} from '@/assets/scripts/custom_validators';
import Cnt from '@/api/contest';
import Breadcrumb from '@/components/Breadcrumb';
import BaseButton from '@/components/BaseComponents/BaseButton';
import BaseCardVideo from '@/components/BaseComponents/BaseCardVideo';
import MorePagination from '@/components/MorePagination';

export default {
    name: 'Internship',
    components: {
        Breadcrumb,
        BaseButton,
        BaseCardVideo,
        MorePagination
    },
    data: () => ( {
        infoQA: [
            {
                'question': 'Как проходит отбор на стажировку?',
                'answer': 'Каждый актив АФК “Система” имеет свою систему отбора. Узнать об этапах можно кликнув по интересующей стажировке.'
            },
            {
                'question': 'Оплачивается ли стажировка?',
                'answer': 'Большинство стажировок оплачивается. Информацию об этом можно найти в описании конкретной стажировки.'
            },
            {
                'question': 'Можно ли совмещать стажировку с работой?',
                'answer': 'Да, удобный график стажировки вы можете обсудить с компанией, в которую вы устраиваетесь.'
            },
            {
                'question': 'Где будет проходить стажировка?',
                'answer': 'Стажировки представлены в разных городах. Воспользуйтесь фильтром стажировок, чтобы найти ближайшую к вам. Некоторые активы АФК предлагают стажировки в онлайн-формате, об этом написано в описании стажировок. Вы также можете выбрать стажировку в другом городе, если у вас есть возможность переехать в другой город на время стажировки.'
            },
            {
                'question': 'Что делать, если я не нашел ничего подходящего?',
                'answer': 'Рекомендуем оставить нам письмо о пожеланиях к стажировкам на почту ',
                'getMail': '<a href="mailto:info@mail.lift-bf.ru">info@mail.lift-bf.ru</a>',
                'answer2': ', чтобы мы проинформировали вас, когда они появятся.'
            },
            {
                'question': 'До какого числа нужно откликнуться на стажировку?',
                'answer': 'Мы пополняем базу стажировок в течение года, поэтому вы можете мониторить страницу и искать подходящую стажировку в любое время. '
            },
        ],
        intern: {
            full_name: '',
            email: '',
            phone: '',
            city: '',
            university: '',
            students: ''
        },
        isPersonal: false,
        isAgree: false,
        isCityClear: false,
        isUniversityClear: false,
        isEducationError: false,
        isSended: false,
        isActive: null,
        isOpenCity: false,
        isOpenD: false,
        isOpenCompany: false,
        selectedCity: '',
        autosearchCity: false,
        currentCity: {
            id: 0,
            text: ''
        },
        currentDirection: {
            id: 0,
            text: ''
        },
        currentCompany: {
            id: 0,
            text: ''
        },
        pseudoCurrent: {
            id: 0,
            text: ''
        },
        currentPage: 1,
        maxPerPage: 6,
        page: 1,
        capabilities: [{
            id: 1,
            image: '/img/landing/internship/feature-6.jpg',
            title: 'Интересные задачи и погружение в актуальную проблематику индустрии'
        }, {
            id: 2,
            image: '/img/landing/internship/feature-24-2.jpg',
            title: 'Топовые компании и более 20 отраслей'
        }, {
            id: 3,
            image: '/img/landing/internship/feature-25.jpg',
            title: 'Возможность стажировки в своем регионе'
        }, {
            id: 4,
            image: '/img/landing/internship/feature-8.jpg',
            title: 'Профессиональные наставники и понятный трек стажировки'
        }, {
            id: 5,
            image: '/img/landing/internship/feature-7.jpg',
            title: 'Практический опыт как самый быстрый способ обучения'
        }, {
            id: 6,
            image: '/img/landing/internship/feature-27.jpg',
            title: 'Перспектива трудоустройства для выдающихся стажеров'
        }]
    }),
    validations: {
        intern: {
            full_name: {required, alphaSpace},
            email: {required, email},
            phone: {required, Phone},
            city: {required, alphaSpace},
            university: {required, alphaEduFac},
            students: {required, onlyNumber}
        },
        isPersonal: {
            sameAs: sameAs(() => true)
        },
        isAgree: {
            sameAs: sameAs(() => true)
        }
    },
    computed: {
        ...mapGetters('is', {
            internships: 'getCatalogue',
            cities: 'getCities',
            directions: 'getDirections',
            companies: 'getCompanies',
        }),
        ...mapGetters('tb', {
            videos: 'getAllVideos'
        }),
        sortedVideos() {
            return this.videos && this.videos.filter(video => video.id !== 7).slice(0, this.currentPage * this.maxPerPage);
        },
        citiesOptions() {
            return this.cities.map(x => x.name).filter(direction => {
                return direction.toLowerCase().includes(this.currentCity.text.toLowerCase())
            })
        },
        directionsOptions() {
            return this.directions.map(x => x.name).filter(direction => {
                return direction.toLowerCase().includes(this.currentDirection.text.toLowerCase())
            })
        },
        companiesOptions() {
            return this.companies.map(x => x.name).filter(company => {
                return company.toLowerCase().includes(this.currentCompany.text.toLowerCase())
            })
        }
    },
    created() {
        Promise.all([
            this.getCatalogue(),
            this.getCities(),
            this.getDirections(),
            this.getCompanies(),
            !this.videos && this.getAllVideos()
        ])
    },
    mounted() {
        this.$scrollTo(document.querySelector('header.header-role'))
    },
    methods: {
        ...mapActions('is', {
            getCatalogue: 'getCatalogue',
            getCities: 'getCities',
            getDirections: 'getDirections',
            getCompanies: 'getCompanies',
        }),
        ...mapActions('tb', [
            'getAllVideos'
        ]),
        toggleItem(index) {
            this.isActive = this.isActive === index ? null : index;
        },
        openDropdownCity() {
            this.isOpenCity = !this.isOpenCity
        },
        isSelectedCity(index) {
            if (this.cities.some(opt => opt === this.currentCity.text) && this.currentCity.id === index) return true
            else return false
        },
        selectOptionCity(index) {
            if (index !== null) {
                this.currentCity.id = index;
                this.currentCity.text = this.cities[index].name
            } else this.currentCity.text = 'Все города'
            this.$emit('selected', this.currentCity.text)
            this.isOpenCity = false
            this.searching()
        },
        openDropdownD() {
            this.isOpenD = !this.isOpenD
        },
        openDropdownCompany() {
            this.isOpenCompany = !this.isOpenCompany
        },
        isPseudoSelected(index) {
            if (this.pseudoCurrent.text && this.pseudoCurrent.id === index) return true
            else return false;
        },
        isSelectedD(index) {
            if (this.directions.some(opt => opt === this.currentDirection.text) && this.currentDirection.id === index) return true
            else return false
        },
        selectOptionD(index) {
            if (index !== null) {
                this.currentDirection.id = index;
                this.currentDirection.text = this.directions[index].name
            } else this.currentDirection.text = 'Все направления'
            this.$emit('selected', this.currentDirection.text)
            this.isOpenD = false
            this.searching()
        },
        isSelectedCompany(index) {
            if (this.companies.some(opt => opt === this.currentCompany.text) && this.currentCompany.id === index) return true
            else return false
        },
        selectOptionCompany(index) {
            if (index !== null) {
                this.currentCompany.id = index;
                this.currentCompany.text = this.companies[index].name
            } else this.currentCompany.text = 'Все компании'
            this.$emit('selected', this.currentCompany.text)
            this.isOpenCompany = false
            this.searching()
        },
        selectUniversity(val) {
            if (val) this.isEducationError = false
            if (!val) this.isEducationError = true
            this.intern.university = val.toUpperCase();
        },
        selectCity(val) {
            this.intern.city = val
        },
        searching() {
            let city = ''
            let direction = ''
            this.cities.forEach(item => {
                if (this.currentCity.text === item.name) {
                    city = item.id
                }
            })
            this.directions.forEach(item => {
                if (this.currentDirection.text === item.name) direction = item.id
            })
            this.getCatalogue({
                cities: this.currentCity.text === 'Все города' ? '' : city,
                directions: this.currentDirection.text === 'Все направления' ? '' : direction,
                name: this.currentCompany.text === 'Все компании' ? '' : this.currentCompany.text
            }).then(catalogue => {
                return this.catalogue === catalogue
            })
        },
        clearFilter() {
            this.currentCity.text = ''
            this.currentDirection.text = ''
            this.currentCompany.text = ''
            this.getCatalogue()
        },
        sendIntern() {
            this.$v.$touch()
            if (this.$v.$invalid) {
                this.$scrollTo('.form')
            } else if (!this.isSended) {
                this.isSended = true
                let [last_name, first_name, middle_name] = this.intern.full_name.split(' ')
                Cnt.internProposal({
                    first_name,
                    middle_name,
                    last_name,
                    city: this.intern.city,
                    phone: this.intern.phone,
                    email: this.intern.email,
                    university: this.intern.university,
                    students: this.intern.students,
                    status: 'pending_moderation'
                })
                    .then(() => {
                        this.modalOpen('ambsdrSuccess')
                        this.$v.intern.$reset()
                        this.$v.isPersonal.$reset()
                        this.$v.isAgree.$reset()
                        this.isCityClear = true
                        this.isUniversityClear = true
                        this.isPersonal = false
                        this.isAgree = false
                        this.intern = {
                            full_name: '',
                            city: '',
                            phone: '',
                            email: '',
                            university: '',
                            students: ''
                        }
                    })
                    .catch(error => this.modalOpen('intrnFail', error.response.data.error_text))
                    .finally(() => this.isSended = false)
            }
        },
        loadMore() {
            this.currentPage += 1;
        },
        ctrlDropdown(e) {
            let elCity = this.$refs.dropdownCity
            let elDirection = this.$refs.dropdownDirection
            let elCompany = this.$refs.dropdownCompany
            let target = e.target
            if (elCity) {
                if ((elCity !== target) && !elCity.contains(target)) {
                    this.isOpenCity = false
                }
            }
            if (elDirection) {
                if ((elDirection !== target) && !elDirection.contains(target)) {
                    this.isOpenD = false
                }
            }
            if (elCompany) {
                if ((elCompany !== target) && !elCompany.contains(target)) {
                    this.isOpenCompany = false
                }
            }
        }
    }
}
</script>

<style lang="less">
@import "../assets/styles/pages/internship";

.item-active {
    display: block;
}

.contest-cover-video {
    video {
        height: 100%;
        width: 100%;
        object-fit: cover;
    }
}

.icon.icon-video-play {
    top: 113px;
}

.send-button {
    min-width: 240px;
    height: 60px;
    line-height: 60px;
    font-family: 'SF Pro Display', sans-serif;
    font-weight: 400;
    font-size: 16px;
    margin-bottom: 20px;
}
</style>
